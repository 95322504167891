import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ValidationVoucherForm } from "./ValidationVoucherForm";
import { VoucherImage } from "./VoucherImage";
import { SendVoucher } from "./SendVoucher";

const steps = [
  "Insert new client data",
  "Preview Voucher",
  "Send email with the voucher attached",
];
export const StepperVoucherCreate = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [clientId, setClientId] = React.useState<string>("");
  const [disabledNext, setDisabledNext] = useState<boolean>(true)
  console.log(clientId);
  

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%"}}>
      <Stepper activeStep={activeStep}>
        {steps.map((lable, index) => {
          const stepProps: { completed?: boolean } = {};
          const lableProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={lable} {...stepProps}>
              <StepLabel {...lableProps}>{lable}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <>
          {activeStep === 0 && <ValidationVoucherForm setClientId={setClientId} setDisabledNext={setDisabledNext} moveToNextStep={handleNext}/>}
          {activeStep === 1 && <VoucherImage clientId={clientId} />}
          {activeStep === 2 && <SendVoucher />}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleNext} disabled={disabledNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
