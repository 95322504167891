import React, { useState } from "react";
import { Header } from "../navigation/Header";
import { Footer } from "../navigation/Footer";
import { Outlet } from "react-router-dom";
import { Container, IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PoolUnreadMessages } from "../../utils/PoolUnreadedMessages";
interface Props {
  signOut: () => void;
}

export const HomePage: React.FC<Props> = ({ signOut }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleDrawerOpen = () => {
    setSidebarOpen(true);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };
  return (
    <>
      <Header signOut={signOut} openSideBar={sidebarOpen} closeSideBar={handleDrawerClose} />
      <PoolUnreadMessages />
      <IconButton
        sx={{
          mt: 10,
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.06)",
          },
        }}
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleDrawerOpen}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <Container
        sx={{
          mt: 20,
          alignItems: "center",
          paddingBottom: "60px",
          mb: 20,
        }}
      >
        <Outlet />
      </Container>
      <Footer />
    </>
  );
};
