import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IProducts } from "../../models/IProducts";

// Action types
export const GET_PRODUCTS = "GetProducts";
export const GET_PRODUCTS_SUCCESS = "GetProductsSuccess";
export const GET_PRODUCTS_ERROR = "GetProductsError";

const isDevelopment = process.env.NODE_ENV === "development";
export const getProducts = createAsyncThunk("products/getProducts", async () => {
  const products = await axios.get(
    isDevelopment
      ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/products`
      : `${process.env.REACT_APP_BACKEND_URL}/products/`
  );

  return products.data;
});

export const createProduct = createAsyncThunk("products/createProduct", async (product: IProducts) => {
  const formData = new FormData();
  formData.append("name", product.name);
  formData.append("price", product.price.toString());
  formData.append("quantity", product.quantity.toString());
  formData.append("season", product.season);
  formData.append("duration", product.duration);
  product.description.forEach((desc, index) => {
    formData.append(`description[${index}][text]`, desc.text);
    formData.append(`description[${index}][icon]`, desc.icon);
  });

  const newProduct = await axios.post(
    isDevelopment
      ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/products/create-product`
      : `${process.env.REACT_APP_BACKEND_URL}/products/create-product`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return newProduct.data;
});

export const deleteProduct = createAsyncThunk("products/deleteProduct", async (productId: string) => {
  const deletedProduct = await axios.delete(
    isDevelopment
      ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/products/delete-product/${productId}`
      : `${process.env.REACT_APP_BACKEND_URL}/products/delete-product/${productId}`
  );

  return deletedProduct.data;
});

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ id, product }: { id: string; product: IProducts }, { rejectWithValue }) => {
    try {
      console.log("product", product);

      const response = await axios.put(
        isDevelopment
          ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/products/update-product/${id}`
          : `${process.env.REACT_APP_BACKEND_URL}/products/update-product/${id}`,
        product
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message || "Failed to update product");
      }
      return rejectWithValue("An error occurred while updating the product");
    }
  }
);
