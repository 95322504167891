import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProducts } from "../models/IProducts";
import { createProduct, deleteProduct, getProducts, updateProduct } from "./actions/getProducts.actions";

interface ProductState {
  products: IProducts[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ProductState = {
  products: [],
  loading: "idle" as "idle" | "pending" | "succeeded" | "failed",
  error: null,
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<IProducts[]>) => {
      state.products = action.payload;
      state.loading = "succeeded";
    },
    setProductsLoading: (state) => {
      state.loading = "pending";
    },
    setProductsError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = "failed";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getProducts.fulfilled, (state, action: PayloadAction<IProducts[]>) => {
        state.products = action.payload;
        state.loading = "succeeded";
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message || "Failed to fetch products";
      })
      .addCase(createProduct.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(createProduct.fulfilled, (state, action: PayloadAction<IProducts>) => {
        state.products.push(action.payload);
        state.loading = "succeeded";
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message || "Failed to create product";
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteProduct.fulfilled, (state, action: PayloadAction<string>) => {
        state.products = state.products.filter((product) => product._id !== action.payload);
        state.loading = "succeeded";
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message || "Failed to delete product";
      })
      .addCase(updateProduct.pending, (state) => {
        state.loading = "pending";
        state.error = null; // Reset error when loading starts
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.products = state.products.map((product) =>
          product._id === action.payload._id ? action.payload : product
        );
        state.error = null; // Clear any error
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message || "Failed to update the product"; // Capture the error
      });
  },
});

export const { setProducts, setProductsLoading, setProductsError } = productsSlice.actions;
export const { actions, reducer } = productsSlice;
export default reducer;
