import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IGoogleDriveSettings } from "../../models/IGoogleDriveSettingsModel";

export const patchGoogleSettings = createAsyncThunk(
  "patch/googleSettings",
  async (data:IGoogleDriveSettings) => {
    const settings = await axios.patch("/settings/updateGoogleSettings", data);

    return settings.data;
  }
);
