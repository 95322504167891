import { createSlice } from "@reduxjs/toolkit";
import { fetchFrontendOrders } from "./actions/fetchFrontendOrders.actions";
import { FrontendOrder } from "../models/IFrontendOrders";

interface OrdersState {
  frontendOrders: FrontendOrder[];
  loadingFrontendOrders: boolean;
  errorFrontendOrders: string | null;
}

const initialState: OrdersState = {
  frontendOrders: [] as FrontendOrder[],
  loadingFrontendOrders: false,
  errorFrontendOrders: null,
};

const frontendOrdersSlice = createSlice({
  name: "frontendOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFrontendOrders.pending, (state) => {
        state.loadingFrontendOrders = true;
        state.errorFrontendOrders = null;
      })
      .addCase(fetchFrontendOrders.fulfilled, (state, action) => {
        state.loadingFrontendOrders = false;
        state.frontendOrders = action.payload;
      })
      .addCase(fetchFrontendOrders.rejected, (state, action) => {
        state.loadingFrontendOrders = false;
        state.errorFrontendOrders = action.payload as string;
      });
  },
});

export const { actions, reducer } = frontendOrdersSlice;
export default reducer;
