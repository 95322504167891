import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import moment from "moment";
import { IWordpressClient } from "../../models/IWordpressClient";
interface UserInformationModalProps {
    open: boolean;
    handleClose: () => void;
    selectedOrder: IWordpressClient | null; 
  }
export const UserInformationModal: React.FC<UserInformationModalProps> = ({ open, handleClose, selectedOrder }) => {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          User Information
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography variant="overline" display="block" gutterBottom>Personal Data</Typography>
                <Divider />
                 Prenume: <Typography>{selectedOrder?.billing[0].first_name}</Typography>
                 Nume: <Typography>{selectedOrder?.billing[0].last_name}</Typography>
                 Email: <Typography>{selectedOrder?.billing[0].email}</Typography>
                 Telefon: <Typography>{selectedOrder?.billing[0].phone}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="overline" display="block" gutterBottom>Voucher Data</Typography>
                <Divider />
                 Nume Voucher: <Typography>{selectedOrder?.line_items[0].name}</Typography>
                Status plata: <Typography>{selectedOrder?.status}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="overline" display="block" gutterBottom>Account Details</Typography>
                <Divider />
                 Client Id: <Typography>{selectedOrder?.order_key}</Typography>
                 Data achizitiei: <Typography> {moment(selectedOrder?.date_created).format("MM/DD/YYYY")}</Typography>
                 Ora achizitiei: <Typography> {moment(selectedOrder?.date_created).format("HH:mm")}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="overline" display="block" gutterBottom>Client Google Drive Data</Typography>
                <Divider />
                 Folder Name: <Typography>{"To be implemented"}</Typography>
                 Video File Name: <Typography>{"To be implemented"}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
};
