import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAppClientOrders } from "../../models/IOrders";

interface FetchArgs {
  sortBy: string;
  order: string;
}
const isDevelopment = process.env.NODE_ENV === "development";
export const fetchAppOrdersClients = createAsyncThunk<IAppClientOrders[], FetchArgs>(
  "clients/fetchAppOrdersClients",
  async ({ sortBy, order }, thunkApi) => {
    const clients = await axios.get<IAppClientOrders[]>(
      isDevelopment
        ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/orders/getAllAppOrders`
        : `${process.env.REACT_APP_BACKEND_URL}/orders/getAllAppOrders`,
      {
        params: {
          order,
          sortBy,
        },
      }
    );

    return clients.data;
  }
);
