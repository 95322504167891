import { createTheme, ThemeProvider } from "@mui/material";

export const generalTheme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          // maxWidth: "100%!important",
          // maxWidth: "1140px!important",
        },
        root: {
          marginTop: "0!important", // Add this line to set the top margin to 0
        },
      },
    },
  },
  typography: {
    fontFamily: '"Chocolate Classical Sans", sans-serif',
    fontWeightRegular: 400,
  },
  palette: {
    primary: {
      main: "#ff5722",
    },
    secondary: {
      main: "#f50057",
    },
    grey: {
      500: "#EEEEEE",
    },
  },
});
