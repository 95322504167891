import { Tabs, Tab, Box } from "@mui/material";
import React, { useState } from "react";
import { TabPanel } from "./TabPanel";
import { GoogleDrive } from "./GoogleDrive";
import { VoucherSettings } from "./VoucherSettings";
import { WordpressSettings } from "./WordpressSettings";

const allyProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

export const Settings = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: '100%',
        }}
      >
        <Tabs
          orientation="vertical"
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="Settings"
          sx={{ borderRight: 1, borderColor: "divider"}}
        >
          <Tab label="Google Drive Settings" {...allyProps(0)} sx={{textAlign: 'left' }}/>
          <Tab label="Voucher Settings" {...allyProps(1)} sx={{textAlign: 'left' }}/>
          <Tab label="Wordpress Settings" {...allyProps(2)} sx={{textAlign: 'left' }}/>
          <Tab label="API Settings" {...allyProps(3)} />
          <Tab label="Orders Settings" {...allyProps(4)} />
          <Tab label="User Settings" {...allyProps(5)} />
          <Tab label="Create User" {...allyProps(5)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <GoogleDrive/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <VoucherSettings />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <WordpressSettings />
        </TabPanel>
        <TabPanel value={value} index={3}>
          API Settings
        </TabPanel>
        <TabPanel value={value} index={4}>
          Orders Settings
        </TabPanel>
        <TabPanel value={value} index={5}>
          User Settings
        </TabPanel>
      </Box>
    </>
  );
};
