import { Drawer, List, ListItem, ListItemText, Divider } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const navigate = useNavigate();

  const navigateToProducts = () => {
    navigate("products");
    onClose();
  };

  const navigateToSettings = () => {
    navigate("settings");
    onClose();
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div role="presentation" onClick={onClose} onKeyDown={onClose} style={{ width: 250 }}>
        <List>
          <ListItem button>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Products" onClick={navigateToProducts} />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Media" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemText primary="Settings" onClick={navigateToSettings} />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Profile" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemText primary="Heat Map" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="SEO" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;
