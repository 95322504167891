import React, { FC, memo, useEffect, useState } from "react";
import { Invoice } from "../../models/IInvoices";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  Grid,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import logo from "../../LogoColor_Kronpara.png";

interface ProductRowProps {
  product: any;
  index: number;
  formik: any;
  error: any;
  touched: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  remove: (index: number) => void;
}
const ProductRow = memo(
  ({
    product,
    index,
    handleChange,
    remove,
    formik,
    error,
    touched,
  }: ProductRowProps) => {
    console.log(product);

    return (
      <TableRow key={index}>
        <TableCell>
          <TextField
            name={`products.${index}.productDescription`}
            value={product.productDescription}
            onChange={handleChange}
            error={Boolean(
              formik.touched.products?.[index]?.productDescription &&
                formik.errors.products?.[index]?.productDescription
            )}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.products?.[index]?.productDescription &&
              formik.errors.products?.[index]?.productDescription
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            name={`products.${index}.unitMeasure`}
            value={product.unitMeasure}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.products?.[index]?.unitMeasure &&
                formik.errors.products?.[index]?.unitMeasure
            )}
            helperText={
              formik.touched.products?.[index]?.unitMeasure &&
              formik.errors.products?.[index]?.unitMeasure
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            name={`products.${index}.quantity`}
            type="number"
            value={product.quantity}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.products?.[index]?.quantity &&
                formik.errors.products?.[index]?.quantity
            )}
            helperText={
              formik.touched.products?.[index]?.quantity &&
              formik.errors.products?.[index]?.quantity
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            name={`products.${index}.unitPrice`}
            type="number"
            value={product.unitPrice}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.products?.[index]?.unitPrice &&
                formik.errors.products?.[index]?.unitPrice
            )}
            helperText={
              formik.touched.products?.[index]?.unitPrice &&
              formik.errors.products?.[index]?.unitPrice
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            name={`products.${index}.vat`}
            type="number"
            value={product.vat}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.products?.[index]?.vat &&
                formik.errors.products?.[index]?.vat
            )}
            helperText={
              formik.touched.products?.[index]?.vat &&
              formik.errors.products?.[index]?.vat
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            name={`products.${index}.total`}
            type="number"
            value={product.total}
            disabled
          />
        </TableCell>
        <TableCell>
          <Tooltip title="Sterge">
            <IconButton onClick={() => remove(index)}>
              <RemoveCircle sx={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
);

export const Invoices: FC = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    success: false,
  });

  const formik = useFormik<Invoice>({
    initialValues: {
      invoiceNumber: "",
      invoiceSeries: "",
      invoiceDate: new Date().toISOString().split("T")[0],
      dueDate: "",
      totalAmount: 0,
      businessLogo: "",
      businessName: "KRONPARA S.R.L",
      businessAddress: "Brasov str. Galaxiei nr.9 bl. 28 sc. C ap. 2",
      businessPhone: "0735628164",
      businessEmail: "kronpara@gmail.com",
      businessCUI: "48426184",
      businessRegistryNumber: "J8/1891/2023",
      businessBank: "BRD",
      businessBankIBAN: "ROBRDE080SV16024680800",
      clientName: "",
      clientAddress: "",
      clientPhone: "",
      clientBusinessName: "",
      clientBusinesRegistryNumber: "",
      clientEmail: "",
      paymentStatus: "",
      paymentNotes: "",
      createdBy: "",
      updatedBy: "",
      products: [
        {
          productDescription: "",
          unitMeasure: "",
          quantity: 0,
          unitPrice: 0,
          vat: 0,
          total: 0,
        },
      ],
    },
    validationSchema: Yup.object({
      invoiceNumber: Yup.string().required("Required"),
      invoiceSeries: Yup.string().required("Required"),
      invoiceDate: Yup.date(),
      dueDate: Yup.date().required("Required"),
      totalAmount: Yup.number().required("Required"),
      businessLogo: Yup.string(),
      businessName: Yup.string(),
      businessAddress: Yup.string(),
      businessPhone: Yup.string(),
      businessEmail: Yup.string(),
      businessCUI: Yup.string(),
      businessRegistryNumber: Yup.string(),
      businessBank: Yup.string(),
      clientName: Yup.string().required("Required"),
      clientAddress: Yup.string().required("Required"),
      clientPhone: Yup.string().required("Required"),
      clientBusinessName: Yup.string(),
      clientBusinesRegistryNumber: Yup.string(),
      clientEmail: Yup.string().required("Required"),
      description: Yup.string(),
      paymentStatus: Yup.string().required("Required"),
      paymentNotes: Yup.string(),
      createdBy: Yup.string(),
      updatedBy: Yup.string(),
      products: Yup.array()
        .of(
          Yup.object({
            productDescription: Yup.string().required("Required"),
            unitMeasure: Yup.string().required("Required"),
            quantity: Yup.number().required("Required"),
            unitPrice: Yup.number().required("Required"),
            vat: Yup.number(),
            total: Yup.number().required("Required"),
          })
        )
        .required("Must have products")
        .min(1, "Minimum of 1 product"),
    }),
    onSubmit: async (values) => {
      console.log("submited", values);
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/invoice/createInvoice`, values);
        setSnackbar({
          open: true,
          message: "Invoice created successfully",
          success: true,
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to create invoice",
          success: false,
        });
      }
    },
  });

  useEffect(() => {
    const updatedProducts = formik.values.products.map((product) => {
      const total = product.quantity * product.unitPrice;
      return { ...product, total };
    });

    if (
      JSON.stringify(updatedProducts) !== JSON.stringify(formik.values.products)
    ) {
      formik.setFieldValue("products", updatedProducts, false);
    }

    const totalPrice = updatedProducts.reduce(
      (acc, product) => acc + product.total,
      0
    );
    setTotalPrice(totalPrice);
  }, [formik.values.products]);

  // Debugging Block
  useEffect(() => {
    console.log("Formik errors:", formik.errors);
    console.log("Formik touched:", formik.touched);
  }, [formik.errors, formik.touched]);

  // PDF Export function
  const handleExport = () => {
    const doc = new jsPDF();
    // Add a logo image (example size, adjust as necessary)
    doc.addImage(logo, "PNG", 14, 10, 48, 28);

    // Add company data on the left side
    doc.setFontSize(10);
    doc.text(`${formik.values.businessName}`, 14, 45);
    doc.text(`Adresa: ${formik.values.businessAddress}`, 14, 50);
    doc.text(`Telefon: ${formik.values.businessPhone}`, 14, 55);
    doc.text(`Email: ${formik.values.businessEmail}`, 14, 60);
    doc.text(`CUI: ${formik.values.businessCUI}`, 14, 65);
    doc.text(`Nr Reg. Com.: ${formik.values.businessRegistryNumber}`, 14, 70);
    doc.text(`Banca: ${formik.values.businessBank}`, 14, 75);
    doc.text(`IBAN: ${formik.values.businessBankIBAN}`, 14, 80);

      // Add client data on the right side
    const clientX = 120;
    doc.setFontSize(10);
    doc.text(`Nume client: ${formik.values.clientName}`, clientX, 45);
    doc.text(`Adresa client: ${formik.values.clientAddress}`, clientX, 50);
    doc.text(`Telefon client: ${formik.values.clientPhone}`, clientX, 55);
    doc.text(`Nume companie client: ${formik.values.clientBusinessName}`, clientX, 60);
    doc.text(`Nr Reg. Com. client: ${formik.values.clientBusinesRegistryNumber}`, clientX, 65);
    doc.text(`Email client: ${formik.values.clientEmail}`, clientX, 70);

     // Add the title 'Invoice' in the middle
    doc.setFontSize(16);
    doc.text('Factura', 90, 100);

    doc.setFontSize(12);
    // Add invoice number on the same line as the series
    doc.text(`Numar factura: ${formik.values.invoiceSeries} ${formik.values.invoiceNumber}`, 80, 105);

    // Add invoice dates below the series and number
    doc.text(`Data facturarii: ${formik.values.invoiceDate}`, 80, 110);
    doc.text(`Termen de plata: ${formik.values.dueDate}`, 80, 115);
    // Create table
    autoTable(doc, {
      startY: 120,
      head: [
        [
          "Produs",
          "U.M",
          "Cantitate",
          "Pret Unitar",
          "TVA",
          "Total",
        ],
      ],
      body: formik.values.products.map((product) => [
        product.productDescription,
        product.unitMeasure,
        product.quantity,
        product.unitPrice,
        product.vat,
        product.total,
      ]),
    });

    // Add total price
    doc.text(
      `Total: ${totalPrice} LEI`,
      164,
      (doc as jsPDF & { lastAutoTable: { finalY: number } }).lastAutoTable
        .finalY + 10
    );

    // Save the PDF
    doc.save("invoice.pdf");
  };

  return (
    <>
      <Typography variant="h4" align="center" gutterBottom>
        Adauga o factura noua
      </Typography>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* Client and Business Details */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                sx={{ m: 2 }}
                type="date"
                label="Data factura"
                {...formik.getFieldProps("invoiceDate")}
                InputLabelProps={{ shrink: true }}
                value={new Date().toISOString().split("T")[0]}
                disabled
              />
              <TextField
                fullWidth
                label="Furnizor"
                {...formik.getFieldProps("businessName")}
                value={"KRONPARA S.R.L"}
                disabled
                sx={{ m: 2 }}
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Addresa"
                {...formik.getFieldProps("businessAddress")}
                value={"Brasov str. Galaxiei nr.9 bl. 28 sc. C ap. 2"}
                disabled
              />
              <TextField
                fullWidth
                label="Telefon"
                sx={{ m: 2 }}
                {...formik.getFieldProps("businessPhone")}
                value={"0735628164"}
                disabled
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Email"
                {...formik.getFieldProps("businessEmail")}
                value={"kronpara@gmail.com"}
                disabled
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="CIF"
                {...formik.getFieldProps("businessCUI")}
                value={"48426184"}
                disabled
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Nr. Reg. Com."
                {...formik.getFieldProps("businessRegistryNumber")}
                value={"J8/1891/2023"}
                disabled
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Banca"
                {...formik.getFieldProps("businessBank")}
                value={"BRD"}
                disabled
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Cont IBAN"
                {...formik.getFieldProps("businessBankIBAN")}
                value={"ROBRDE080SV16024680800"}
                disabled
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                type="date"
                label="Termen de plata **Optional"
                {...formik.getFieldProps("dueDate")}
                InputLabelProps={{ shrink: true }}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.dueDate && formik.errors.dueDate)}
                helperText={formik.touched.dueDate && formik.errors.dueDate}
              />
              <TextField
                fullWidth
                label="Numar factura"
                sx={{ m: 2 }}
                {...formik.getFieldProps("invoiceNumber")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.invoiceNumber && formik.errors.invoiceNumber
                )}
                helperText={
                  formik.touched.invoiceNumber && formik.errors.invoiceNumber
                }
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Serie Factura"
                {...formik.getFieldProps("invoiceSeries")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.invoiceSeries && formik.errors.invoiceSeries
                )}
                helperText={
                  formik.touched.invoiceSeries && formik.errors.invoiceSeries
                }
              />
              <FormControl fullWidth sx={{ m: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  {...formik.getFieldProps("paymentStatus")}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched.paymentStatus && formik.errors.paymentStatus
                  )}
                >
                  <MenuItem value={"NEPLATIT"}>NEPLATIT</MenuItem>
                  <MenuItem value={"PLATIT"}>PLATIT</MenuItem>
                  <MenuItem value={"ANULAT"}>ANULAT</MenuItem>
                </Select>
                {formik.touched.paymentStatus &&
                  formik.errors.paymentStatus && (
                    <Typography color="error" variant="caption">
                      {formik.errors.paymentStatus}
                    </Typography>
                  )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Nume Client"
                {...formik.getFieldProps("clientName")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.clientName && formik.errors.clientName
                )}
                helperText={
                  formik.touched.clientName && formik.errors.clientName
                }
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Adresa Client"
                {...formik.getFieldProps("clientAddress")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.clientAddress && formik.errors.clientAddress
                )}
                helperText={
                  formik.touched.clientAddress && formik.errors.clientAddress
                }
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Telefon Client"
                {...formik.getFieldProps("clientPhone")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.clientPhone && formik.errors.clientPhone
                )}
                helperText={
                  formik.touched.clientPhone && formik.errors.clientPhone
                }
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Nume Companie Client"
                {...formik.getFieldProps("clientBusinessName")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.clientBusinessName &&
                    formik.errors.clientBusinessName
                )}
                helperText={
                  formik.touched.clientBusinessName &&
                  formik.errors.clientBusinessName
                }
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Numar Reg. Com. Client"
                {...formik.getFieldProps("clientBusinesRegistryNumber")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.clientBusinesRegistryNumber &&
                    formik.errors.clientBusinesRegistryNumber
                )}
                helperText={
                  formik.touched.clientBusinesRegistryNumber &&
                  formik.errors.clientBusinesRegistryNumber
                }
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Email Client"
                {...formik.getFieldProps("clientEmail")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.clientEmail && formik.errors.clientEmail
                )}
                helperText={
                  formik.touched.clientEmail && formik.errors.clientEmail
                }
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Payment Notes"
                {...formik.getFieldProps("paymentNotes")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.paymentNotes && formik.errors.paymentNotes
                )}
                helperText={
                  formik.touched.paymentNotes && formik.errors.paymentNotes
                }
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Factura creata de"
                {...formik.getFieldProps("createdBy")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.createdBy && formik.errors.createdBy
                )}
                helperText={formik.touched.createdBy && formik.errors.createdBy}
              />
              <TextField
                fullWidth
                sx={{ m: 2 }}
                label="Editata de"
                {...formik.getFieldProps("updatedBy")}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.updatedBy && formik.errors.updatedBy
                )}
                helperText={formik.touched.updatedBy && formik.errors.updatedBy}
              />
            </Grid>
            <Grid item xs={12}>
              {/* Products Table */}
              <FieldArray name="products">
                {({ push, remove }) => (
                  <>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Denumire Produs sau servicii</TableCell>
                            <TableCell>U.M</TableCell>
                            <TableCell>Cantitate</TableCell>
                            <TableCell>Pret unitar</TableCell>
                            <TableCell>TVA</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Actiuni</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {formik.values.products.map((product, index) => (
                            <ProductRow
                              formik={formik}
                              error={formik.errors}
                              touched={formik.touched}
                              key={index}
                              product={product}
                              index={index}
                              handleChange={formik.handleChange}
                              remove={remove}
                            />
                          ))}
                          <TableRow>
                            <TableCell colSpan={8}>
                              <Button
                                startIcon={<AddCircle />}
                                onClick={() =>
                                  push({
                                    productDescription: "",
                                    unitMeasure: "",
                                    quantity: 0,
                                    unitPrice: 0,
                                    vat: 0,
                                    total: 0,
                                  })
                                }
                              >
                                Adauga produse
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={8}>
                              <Typography
                                variant="h6"
                                align="right"
                                sx={{ p: 2, mt: 2 }}
                              >
                                Total {totalPrice} LEI
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </FieldArray>
              <Grid item sx={{ p: 2, mt: 2 }} spacing={3} lg={12}>
                <Box sx={{ "& > :not(style)": { m: 1 } }}>
                  <Button
                    disabled={!(formik.isValid && formik.dirty)}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button color="secondary" variant="contained">
                    Edit
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={handleExport}
                  >
                    Export
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          message={snackbar.message}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      </FormikProvider>
    </>
  );
};
