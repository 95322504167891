import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setErrorGlobal, setSuccessGlobal } from "../../state/notificationSlice";

interface ValidationVoucherFormProps {
  setClientId: React.Dispatch<React.SetStateAction<string>>;
  setDisabledNext: React.Dispatch<React.SetStateAction<boolean>>;
  moveToNextStep: () => void;
}

export const ValidationVoucherForm: React.FC<ValidationVoucherFormProps> = ({
  setClientId,
  setDisabledNext,
  moveToNextStep
}) => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    voucherTitle: Yup.string().required("Title is required"),
    last_name: Yup.string().required("Name is required"),
    first_name: Yup.string().required("First name is required"),
    phone: Yup.string()
      .matches(
        /^[0-9]{10}$/,
        "Not a valid telephone number. Must be exactly 10 digits"
      )
      .required("Phone number is required"),
    voucherEmail: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      clientId: uuidv4(),
      voucherTitle: "",
      last_name: "",
      first_name: "",
      phone: "",
      voucherEmail: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const folderName = `${values.first_name}_${values.last_name}`;
        setClientId(values.clientId);
        setDisabledNext(false);
        const dataToSend = {
          ...values,
          folderName,
        };

        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/newOrders/createOrder`,
          dataToSend
        );
        dispatch(setSuccessGlobal({ message: "User created successfully!" }));
        moveToNextStep();
      } catch (error) {
        dispatch(setErrorGlobal({ message: `Failed to create user! ${error}` }));
      }
    },
  });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 5,
        gap: 2,
      }}
    >
      <TextField
        id="standard-basic"
        label="Locatia zborului"
        name="voucherTitle"
        value={formik.values.voucherTitle}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.voucherTitle && !!formik.errors.voucherTitle}
        helperText={formik.touched.voucherTitle && formik.errors.voucherTitle}
        variant="standard"
        sx={{ width: 250 }}
      />
      <TextField
        id="standard-basic"
        label="Nume"
        name="last_name"
        value={formik.values.last_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.last_name && !!formik.errors.last_name}
        helperText={formik.touched.last_name && formik.errors.last_name}
        variant="standard"
        sx={{ width: 250 }}
      />
      <TextField
        id="standard-basic"
        label="Prenume"
        name="first_name"
        value={formik.values.first_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.first_name && !!formik.errors.first_name}
        helperText={formik.touched.first_name && formik.errors.first_name}
        variant="standard"
        sx={{ width: 250 }}
      />
      <TextField
        id="standard-basic"
        label="Telefon"
        name="phone"
        value={formik.values.phone}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.phone && !!formik.errors.phone}
        helperText={formik.touched.phone && formik.errors.phone}
        variant="standard"
        sx={{ width: 250 }}
      />
      <TextField
        id="standard-basic"
        label="Email"
        name="voucherEmail"
        value={formik.values.voucherEmail}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.voucherEmail && !!formik.errors.voucherEmail}
        helperText={formik.touched.voucherEmail && formik.errors.voucherEmail}
        variant="standard"
        sx={{ width: 250 }}
      />
      <Box>
        <Button
          sx={{ mt: 8, mb: 14}}
          disabled={!formik.isValid || formik.isSubmitting}
          variant="contained"
          onClick={() => {
            formik.validateForm().then((errors) => {
              if (Object.keys(errors).length === 0) {
                formik.handleSubmit();
              }
            });
          }}
        >
          Create
        </Button>
      </Box>
    </Box>
  );
};
