import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setUnreadMessages } from "../state/notificationSlice";

const isDevelopment = process.env.NODE_ENV === "development";

export const PoolUnreadMessages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const pollUnreadMessages = async () => {
      try {
        const response = await axios.get(
          isDevelopment
            ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/messages/unread-messages`
            : `${process.env.REACT_APP_BACKEND_URL}/messages/unread-messages`
        );
        dispatch(setUnreadMessages(response.data.unreadMessages));
      } catch (err) {
        console.error("Failed to fetch unread messages", err);
      }
    };

    const interval = setInterval(pollUnreadMessages, 50000); // Poll every 5 seconds
    return () => clearInterval(interval);
  }, [dispatch]);

  return null;
};
