import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SaveIcon from "@mui/icons-material/Save";
// import IFile from "../../models/IFile";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const VoucherSettings = () => {
    // const [backgroundImage, setBackgroundImage] = useState<File>();
    // const [fileInfos, setFileInfos] = useState<IFile[]>([]);
    // const [uploadProgress, setUploadProgress] = useState<number>(0);

    const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        // const { files } = event.target;
        // const selectedFiles = files as FileList;
        // const selectedFile = selectedFiles[0];
    
        // const fileInfo: IFile = {
        //   name: selectedFile.name,
        //   size: `${(selectedFile.size / (1024 * 1024)).toFixed(2)} MB`,
        //   progress: 0,
        //   uploaded: false,
        // };
    
        // setBackgroundImage(selectedFiles?.[0]);
        // setFileInfos([fileInfo]);
        // setUploadProgress(0);
      };
  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            component="label"
            variant="contained"
            startIcon={<UploadFileIcon />}
            sx={{ mr: 2, ml: 1, mt: 1 }}
          >
            Select image
            <VisuallyHiddenInput type="file" onChange={selectFile} />
          </Button>
          <Typography sx={{ mt: 1 }}>Add a background image for Voucher</Typography>
        </Box>
      </Box>
      <Button variant="contained" startIcon={<SaveIcon />} sx={{mt: 3, ml: 1}} color="success">
        Save
      </Button>
    </>
  );
};
