import { MenuItem, AppBar, Toolbar, Typography, Container, Badge, Box, IconButton } from "@mui/material";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import Sidebar from "../sidebar/sidebar";
import MailIcon from "@mui/icons-material/Mail";

interface Props {
  signOut: () => void;
  openSideBar: boolean;
  closeSideBar: () => void;
}

export const Header: React.FC<Props> = ({ signOut, openSideBar, closeSideBar }) => {
  const navigate = useNavigate();

  const newOrder = useSelector((state: RootState) => state.notifications.newOrder);

  const unreadMessages = useSelector((state: RootState) => state.notifications.unreadMessages);

  return (
    <AppBar>
      <Container>
        <>
          <Toolbar disableGutters>
            <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              CodeCraft Innovations
            </Typography>
            <MenuItem onClick={() => navigate("orders")}>
              <Badge badgeContent={newOrder} color="primary">
                <Typography textAlign="center">Orders</Typography>
              </Badge>
            </MenuItem>
            <MenuItem onClick={() => navigate("upload")}>
              <Typography textAlign="center">Upload video</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate("voucher")}>
              <Typography textAlign="center">Create Voucher</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate("invoices")}>Invoices</MenuItem>
            <MenuItem onClick={() => navigate("/account")}>My account</MenuItem>

            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => navigate("messages")} color="inherit">
              <Badge badgeContent={unreadMessages} color="primary">
                <MailIcon />
              </Badge>
            </IconButton>
            <MenuItem onClick={signOut}> Logout</MenuItem>
          </Toolbar>
          <Sidebar open={openSideBar} onClose={closeSideBar} />
        </>
      </Container>
    </AppBar>
  );
};
