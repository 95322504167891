import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import type { AppDispatch, RootState } from "../../state/store";
import MailIcon from "@mui/icons-material/Mail";
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { style } from "./style";
import { StepperVoucherCreate } from "./Stepper";
import { fetchWordpessClients } from "../../state/actions/fetchWordpressClient.actions";
import axios from "axios";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Popover from "@mui/material/Popover";
import InfoIcon from "@mui/icons-material/Info";
import LoadingButton from "@mui/lab/LoadingButton";

export const Voucher = () => {
  const dispatch = useDispatch<AppDispatch>();

  // const wordpressClient = useSelector(
  //   (state: RootState) => state.wordpressClient.clients
  // );

  const {
    clients: wordpressClient,
    loadingWebClients: clientsLoading,
    errorWebClients: clientsError,
  } = useSelector((state: RootState) => state.wordpressClient);

  //Snackbar state
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const imageRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    dispatch(fetchWordpessClients({ sortBy: "date_created", order: "desc" }));
    setLoading(false);
  }, [dispatch]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const saveImage = () => {
    if (imageRef.current) {
      html2canvas(imageRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("voucher/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "dynamic-voucher.png";
        link.click();
      });
    }
  };

  const sendVoucherToEmail = () => {
    if (imageRef.current) {
      html2canvas(imageRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("voucher/png");
        sendImageToServer(imgData);
      });
    }
  };

  const sendImageToServer = async (base64Image: any) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/email/sendEmail`, {
        image: base64Image,
        client: {
          email: wordpressClient[0].billing[0].email,
          clientId: wordpressClient[0].id,
          first_name: wordpressClient[0].billing[0].first_name,
          last_name: wordpressClient[0].billing[0].last_name,
          clientName: `${wordpressClient[0].billing[0].first_name} ${wordpressClient[0].billing[0].last_name}`,
          phone: wordpressClient[0].billing[0].phone,
          voucher: wordpressClient[0].line_items[0].name,
          payment_status: wordpressClient[0].status,
        },
      });

      if (response.status === 200) {
        setMessage("Email sent successfully");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setMessage("Error sending email");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };
  const client = wordpressClient.length > 0 ? wordpressClient[0] : null;
  const lineItems = client ? client.line_items : null;

  console.log("lineItems", !lineItems);

  console.log("client", !client);

  return (
    <>
      {clientsLoading === "pending" ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      ) : clientsError ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Typography variant="h4">
            Error loading data. {clientsError}
          </Typography>
        </Box>
      ) : !client && !lineItems ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Typography variant="h4">No data from server</Typography>
        </Box>
      ) : (
        <Container>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            message={message}
          />

          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              ref={imageRef}
              sx={{
                width: "45rem",
                height: "20rem",
                backgroundColor: "#3f50b5",
                position: "relative",
                backgroundImage: `url('/images/bg-new.jpeg')`,
                backgroundPosition: "25% center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
                zIndex: 1,
                padding: 2,
              }}
            >
              {/* Overlay Box */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  zIndex: 2,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 3,
                  backgroundPosition: "left center",
                }}
              >
                <img
                  src="/images/Logo_color.png"
                  alt="Logo"
                  style={{
                    marginLeft: 0,
                    width: "190px",
                    height: "auto",
                    float: "left",
                  }}
                />
                <Box
                  sx={{
                    backgroundColor: "#3f50b5",
                    borderBottom: "1px white",
                    zIndex: 105,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      zIndex: 106,
                      color: "#FAFAFA",
                      fontWeight: "500",
                    }}
                    gutterBottom
                  >
                    {wordpressClient[0].line_items[0].name}
                  </Typography>
                </Box>
                {/* Order ID */}
                <Typography
                  variant="h6"
                  sx={{
                    zIndex: 106,
                    color: "#FAFAFA",
                    fontWeight: "500",
                    textAlign: "right",
                    marginRight: 2,
                    fontSize: 15,
                  }}
                  gutterBottom
                >
                  Numar de comanda: #{wordpressClient[0].id}
                </Typography>
                <Typography
                  sx={{
                    zIndex: 3,
                    fontSize: 15,
                    color: "#FAFAFA",
                    textAlign: "right",
                    marginRight: 2,
                  }}
                >
                  Valabil din data de:{" "}
                  {new Date(
                    wordpressClient[0].date_created
                  ).toLocaleDateString()}
                </Typography>
                <Box sx={{ textAlign: "right", marginRight: 2 }}>
                  <QRCode
                    value="https://www.kronpara.ro"
                    size={80}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="Q"
                    includeMargin={true}
                  />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", mt: 20, ml: 3 }}
              >
                <>
                  <Typography
                    align="left"
                    sx={{
                      zIndex: 3,
                      fontSize: 18,
                      color: "#FAFAFA",
                      marginLeft: 30,
                    }}
                  >
                    Vă rugăm să ne contactați cu 48 de ore înainte de data
                    dorită pentru zbor, pentru a putea verifica condițiile
                    meteorologice și disponibilitatea locurilor.
                  </Typography>
                  <Typography
                    align="center"
                    sx={{
                      zIndex: 3,
                      fontSize: 20,
                      color: "#FAFAFA",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      marginTop: 2,
                      fontWeight: 600,
                    }}
                  >
                    <LocalPhoneIcon />
                    Telefon: 0735 628 164
                  </Typography>
                </>
              </Box>
              <Grid
                container
                sx={{
                  display: "flex",
                  height: "30px",
                  marginTop: 1,
                  borderTop: "1px solid white",
                  backgroundColor: "#3f50b5",
                  zIndex: 100000,
                  padding: 0,
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              >
                <Typography
                  sx={{
                    color: "#FAFAFA",
                    fontSize: 13,
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    textAlign: "left",
                    height: "30px",
                    display: "flex",
                    marginLeft: 1,
                    zIndex: 300,
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: 'Chocolate Classical Sans", sans-serif',
                    fontWeight: 400,
                    fontStyle: "normal",
                  }}
                  display="block"
                >
                  Valabilitatea voucherului este 1 an de zile de la data
                  achizitionarii | wwww.kronpara.ro | kronpara@gmail.com
                </Typography>
              </Grid>
            </Box>
          </Grid>

          {/* Buttons */}
          <Button
            aria-describedby={id}
            variant="contained"
            endIcon={<InfoIcon />}
            onClick={handleClick}
            sx={{ mt: 2 }}
          >
            Detalii client
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ padding: 2 }}
          >
            <Box sx={{ p: 2 }}>
              <Typography sx={{ p: 2 }}>Datele clientului.</Typography>
              <Typography
                align="left"
                sx={{
                  zIndex: 3,
                  fontSize: 12,
                }}
              >
                Nume:{" "}
                {`${wordpressClient[0].billing[0].first_name} ${wordpressClient[0].billing[0].last_name}`}
              </Typography>
              <Typography
                align="left"
                sx={{
                  zIndex: 3,
                  fontSize: 12,
                }}
              >
                Telefon: {wordpressClient[0].billing[0].phone}
              </Typography>
              <Typography
                align="left"
                sx={{
                  zIndex: 3,
                  fontSize: 12,
                }}
              >
                Email: {wordpressClient[0].billing[0].email}
              </Typography>
            </Box>
          </Popover>
          <Box sx={{ mt: 10, display: "flex", gap: 1 }}>
            <Button
              variant="contained"
              color="success"
              onClick={saveImage}
              startIcon={<SaveAltIcon />}
              sx={{ whiteSpace: "nowrap" }}
            >
              Save Voucher
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={sendVoucherToEmail}
              sx={{ whiteSpace: "nowrap" }}
              startIcon={<MailIcon />}
            >
              Send voucher to email
            </LoadingButton>
            <Button
              variant="contained"
              sx={{ whiteSpace: "nowrap" }}
              onClick={handleOpenModal}
              startIcon={<AddIcon />}
            >
              New Voucher
            </Button>
          </Box>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <StepperVoucherCreate />
            </Box>
          </Modal>
        </Container>
      )}
    </>
  );
};
