import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { showToast } from "../utils/tools";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../state/store";
import { clearNotification } from "../state/notificationSlice";

const MainLayout = (props: { children: any }) => {
  const notifications = useSelector((state: RootState) => state.notifications);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (notifications && notifications.error) {
      const msg = notifications.msg ? notifications.msg : "Error";
      showToast("ERROR", msg);
      dispatch(clearNotification())
    }
    if (notifications && notifications.success) {
      const msg = notifications.msg ? notifications.msg : "Success";
      showToast("SUCCESS", msg);
      dispatch(clearNotification())
    }
  }, [notifications, dispatch]);
  return (
    <>
      {props.children}
      <ToastContainer />
    </>
  );
};

export default MainLayout;
