import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, type RootState } from "../../state/store";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  FormControl,
  MenuItem,
  Typography,
  CircularProgress,
  Button,
  Box,
  TextField,
  Tabs,
  Tab,
  Backdrop,
  Container,
  Grid,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import moment from "moment";
import SendIcon from "@mui/icons-material/Send";
import SortIcon from "@mui/icons-material/Sort";
import CloseIcon from "@mui/icons-material/Close";
import { UserInformationModal } from "./UserInformationModal";
import { fetchWordpessClients } from "../../state/actions/fetchWordpressClient.actions";
import { IWordpressClient } from "../../models/IWordpressClient";
import { a11yProps, CustomTabPanel } from "./tableInterface";
import { IAppClientOrders } from "../../models/IOrders";
import { fetchAppOrdersClients } from "../../state/actions/fetchAppOrdersClient.actions";
import axios from "axios";
import { fetchFrontendOrders } from "../../state/actions/fetchFrontendOrders.actions";
import { FrontendOrder } from "../../models/IFrontendOrders";
import DownloadIcon from "@mui/icons-material/Download";

export const Orders: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const { clients, loadingWebClients, errorWebClients } = useSelector((state: RootState) => state.wordpressClient);

  const { appClients, loading, error } = useSelector((state: RootState) => state.appOrders);

  const { frontendOrders, loadingFrontendOrders, errorFrontendOrders } = useSelector(
    (state: RootState) => state.frontendOrders
  );

  const [value, setValue] = React.useState(0);
  const [clickedFilter, setClickedFilter] = useState<boolean>(false);
  const [order, setOrder] = useState<string>("desc");
  const [sortBy, setSortBy] = useState<string>("date_created");
  const [filterText, setFilterText] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [backdropOpen, setBackdropOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<IWordpressClient | null>(null);
  const [selectedFrontendOrder, setSelectedFrontendOrder] = useState<FrontendOrder | null>(null);
  const [selectedAppOrder, setSelectedAppOrder] = useState<IAppClientOrders | null>(null);

  // FETCH THE WP CLIENTS FROM LOCAL STORE(MONGO)
  useEffect(() => {
    dispatch(fetchWordpessClients({ sortBy: sortBy, order: "desc" }));
    dispatch(fetchAppOrdersClients({ sortBy: sortBy, order: "desc" }));
    dispatch(fetchFrontendOrders({ sortBy: sortBy, order: "desc" }));
  }, [dispatch, order, sortBy]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  console.log("FRONEND APP CLIENTS LOG:", frontendOrders);

  const filteredClients = Array.isArray(appClients)
    ? appClients.filter((order) => {
        const searchString = filterText.toLowerCase();
        return (
          order.first_name.toLowerCase().includes(searchString) ||
          order.last_name.toLowerCase().includes(searchString) ||
          order.orderEmail.toLowerCase().includes(searchString)
        );
      })
    : [];

  // const resendEmail = async (order: IFilteredOrders) => {
  //   try {
  //     await axios.post("/orders/resendEmail", {
  //       email: order.orderEmail,
  //       id: order.id,
  //     });
  //     setResendEmailMessage(`Resent email to ${order.orderEmail}`);
  //   } catch (error) {
  //     setResendErrorMessage(`Failed to send email to ${order.orderEmail}`);
  //   }
  // };

  const handleSortWordpressOrders = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setOrder(value);
    dispatch(fetchWordpessClients({ sortBy: sortBy, order: value }));
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setSortBy(value);
  };

  const handleDownloadClick = (event: any, voucherId: string) => {
    event.stopPropagation();
    downloadVoucher(voucherId);
  };

  const downloadVoucher = async (voucherId: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/voucher/getVoucher/${voucherId}`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "voucher.png");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      console.error("Error downloading voucher", error);
      if (error.response && error.response.status === 404) {
        setErrorMessage("The image doesn't exist.");
        setBackdropOpen(true);
      }
    }
  };

  return (
    <Grid container>
      <Typography variant="h5" sx={{}}>
        Comenzi
      </Typography>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
        onClick={() => setBackdropOpen(false)}
      >
        <Typography variant="h6">{errorMessage}</Typography>
      </Backdrop>
      {loadingWebClients === "pending" ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress sx={{ mt: 10 }} />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="Comenzi site(Wordpress)" {...a11yProps(0)} />
              <Tab label="Comenzi Aplicatie" {...a11yProps(1)} />
              <Tab label="Comenzi Site(Nou)" {...a11yProps(2)} />
            </Tabs>
          </Box>
          {/* Comenzi Kronpara Wordpress site(vechi) */}
          <CustomTabPanel value={value} index={0}>
            <Grid sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 6 }}>
                <Typography>Filter by:</Typography>
                {!clickedFilter ? (
                  <SortIcon
                    sx={{
                      "&:hover": { cursor: "pointer", boxShadow: 1 },
                      ml: 1,
                    }}
                    onClick={() => setClickedFilter(!clickedFilter)}
                  />
                ) : (
                  <FormControl>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Select
                        labelId="order-orders"
                        id="order"
                        value={order}
                        onChange={handleSortWordpressOrders}
                        size="small"
                        sx={{ ml: 1 }}
                      >
                        <MenuItem value={"asc"}>Data ascending</MenuItem>
                        <MenuItem value={"desc"}>Data descending</MenuItem>
                      </Select>
                      <Select
                        labelId="sortBy"
                        id="sortBy"
                        value={sortBy}
                        onChange={handleSortChange}
                        size="small"
                        sx={{ ml: 1 }}
                      >
                        <MenuItem value={"date_created"}>Data creata</MenuItem>
                        {/* <MenuItem value={"desc"}>(Data) descrescator</MenuItem> */}
                      </Select>
                      <CloseIcon
                        onClick={() => setClickedFilter(false)}
                        sx={{ ml: 1, "&:hover": { cursor: "pointer" } }}
                        color="error"
                      />
                    </Box>
                  </FormControl>
                )}
                <TextField
                  label="Cauta client"
                  variant="outlined"
                  size="small"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  sx={{ ml: 4 }}
                />
              </Box>
              <Paper>
                <TableContainer sx={{ mt: 10, maxWidth: "1200px", width: "100%", margin: "0 auto" }}>
                  <Table aria-label="Orders table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Data</TableCell>
                        <TableCell align="center">Ora</TableCell>
                        <TableCell align="center">Nume</TableCell>
                        <TableCell align="center">Prenume</TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">Telefon</TableCell>
                        <TableCell align="center">Voucher</TableCell>
                        {/* <TableCell align="center">Status</TableCell>
                        <TableCell align="center"> Status Email</TableCell> */}
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    {!clients ? (
                      <TableBody>
                        <TableRow sx={{ backgroundColor: "lightgrey" }}>
                          <TableCell colSpan={10} sx={{ textAlign: "center", verticalAlign: "middle" }}>
                            <Typography sx={{ p: 2 }}>No data</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {clients.length > 0 ? (
                          clients
                            .filter((order) => {
                              const searchString = filterText.toLowerCase();
                              return (
                                order.billing.some((billing) => billing.email.toLowerCase().includes(searchString)) ||
                                order.billing.some((billing) =>
                                  billing.first_name.toLowerCase().includes(searchString)
                                ) ||
                                order.billing.some((billing) => billing.last_name.toLowerCase().includes(searchString))
                              );
                            })
                            .map((order) => (
                              <TableRow
                                key={order.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  "&:hover": { background: theme.palette.grey[500] },
                                  height: "3rem",
                                }}
                                onClick={() => {
                                  setSelectedOrder(order);
                                  setOpen(true);
                                }}
                              >
                                <TableCell align="center" component="th" scope="row">
                                  {moment(order.date_created).format("MM/DD/YYYY")}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                  {moment(order.date_created).format("HH:MM")}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                  {order.billing.map((billing) => billing.first_name)}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                  {order.billing.map((billing) => billing.last_name)}
                                </TableCell>
                                <TableCell align="center" component="th" sx={{ width: "5%" }} scope="row">
                                  {order.billing.map((billing) => billing.email)}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                  {order.billing.map((billing) => billing.phone)}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                  {order.line_items.map((line_items) => line_items.name)}
                                </TableCell>
                                {/* <TableCell component="th" scope="row">
                                  {"To be implemented"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {"To be implemented"}
                                </TableCell> */}
                                <TableCell component="th" scope="row">
                                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Tooltip title="Resend Email">
                                      <IconButton
                                        aria-label="resend"
                                        disabled={true}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // resendEmail(order);
                                        }}
                                      >
                                        {" "}
                                        <SendIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Download Voucher">
                                      <IconButton disabled aria-label="download">
                                        <DownloadIcon color="success" />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <Typography>No data to display</Typography>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </CustomTabPanel>

          {/* Comenzi facute direct in aplicatie (back office) */}
          <CustomTabPanel value={value} index={1}>
            {/* Comenzi aplicatie */}
            <Box sx={{ width: "100%" }}>
              {/* Filter section */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography>Filter by:</Typography>
                {!clickedFilter ? (
                  <SortIcon
                    sx={{
                      "&:hover": { cursor: "pointer", boxShadow: 1 },
                      ml: 1,
                    }}
                    onClick={() => setClickedFilter(!clickedFilter)}
                  />
                ) : (
                  <FormControl>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Select
                        labelId="order-orders"
                        id="order"
                        value={order}
                        onChange={handleSortWordpressOrders}
                        size="small"
                        sx={{ ml: 1 }}
                      >
                        <MenuItem value={"asc"}>Data ascending</MenuItem>
                        <MenuItem value={"desc"}>Data descending</MenuItem>
                      </Select>
                      <Select
                        labelId="sortBy"
                        id="sortBy"
                        value={sortBy}
                        onChange={handleSortChange}
                        size="small"
                        sx={{ ml: 1 }}
                      >
                        <MenuItem value={"date_created"}>Data creata</MenuItem>
                        {/* <MenuItem value={"desc"}>(Data) descrescator</MenuItem> */}
                      </Select>
                      <CloseIcon
                        onClick={() => setClickedFilter(false)}
                        sx={{ ml: 1, "&:hover": { cursor: "pointer" } }}
                        color="error"
                      />
                    </Box>
                  </FormControl>
                )}
                <TextField
                  label="Cauta client"
                  variant="outlined"
                  size="small"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  sx={{ ml: 4 }}
                />
              </Box>

              {/* Loading end error section */}
              {loading === "pending" ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : error ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <Typography variant="h6" color="error">
                    Error loading data: {error}
                  </Typography>
                </Box>
              ) : (
                <Paper>
                  <TableContainer sx={{ mt: 10, width: "100%", margin: "0 auto", maxWidth: "1200" }}>
                    <Table sx={{ width: "100%" }} size="small" aria-label="Orders table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Seria</TableCell>
                          <TableCell align="center">Data</TableCell>
                          <TableCell align="center">Ora</TableCell>
                          <TableCell align="center">Nume</TableCell>
                          <TableCell align="center">Prenume</TableCell>
                          <TableCell align="center">Email</TableCell>
                          <TableCell align="center">Telefon</TableCell>
                          <TableCell align="center">Voucher</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Status Email
                          </TableCell>
                          <TableCell align="center">Action</TableCell>
                          <TableCell align="center">Download Voucher</TableCell>
                        </TableRow>
                      </TableHead>
                      {!appClients ? (
                        <TableBody>
                          <TableRow sx={{ backgroundColor: "lightgrey" }}>
                            <TableCell
                              colSpan={10}
                              sx={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>No data</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {filteredClients.length > 0 ? (
                            filteredClients.map((order) => (
                              <TableRow
                                key={order.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  "&:hover": { background: "lightgray" },
                                  height: "3rem",
                                }}
                                onClick={() => {
                                  setSelectedAppOrder(order);
                                  setOpen(true);
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {order.clientId.substring(0, 8)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {moment(order.createdAt).format("MM/DD/YYYY")}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {moment(order.createdAt).format("HH:MM")}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {order.first_name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {order.last_name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {order.orderEmail}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {order.phone}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {order.voucher}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {"To be implemented"}
                                </TableCell>
                                <TableCell component="th" sx={{ width: "10%", textAlign: "center" }} scope="row">
                                  {"To be implemented"}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    startIcon={<SendIcon />}
                                    variant="contained"
                                    disabled={true}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // resendEmail(order);
                                    }}
                                  >
                                    Resend
                                  </Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    onClick={(event) => handleDownloadClick(event, order.clientId)}
                                    variant="contained"
                                    color="success"
                                  >
                                    Download
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <Typography>No data to display</Typography>
                          )}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Paper>
              )}
            </Box>
          </CustomTabPanel>
          {/* Comenzi Kronpara Frontend */}
          <CustomTabPanel value={value} index={2}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 6 }}>
                <Typography>Filter by:</Typography>
                {!clickedFilter ? (
                  <SortIcon
                    sx={{
                      "&:hover": { cursor: "pointer", boxShadow: 1 },
                      ml: 1,
                    }}
                    onClick={() => setClickedFilter(!clickedFilter)}
                  />
                ) : (
                  <FormControl>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Select
                        labelId="order-orders"
                        id="order"
                        value={order}
                        onChange={handleSortWordpressOrders}
                        size="small"
                        sx={{ ml: 1 }}
                      >
                        <MenuItem value={"asc"}>Data ascending</MenuItem>
                        <MenuItem value={"desc"}>Data descending</MenuItem>
                      </Select>
                      <Select
                        labelId="sortBy"
                        id="sortBy"
                        value={sortBy}
                        onChange={handleSortChange}
                        size="small"
                        sx={{ ml: 1 }}
                      >
                        <MenuItem value={"date_created"}>Data creata</MenuItem>
                        {/* <MenuItem value={"desc"}>(Data) descrescator</MenuItem> */}
                      </Select>
                      <CloseIcon
                        onClick={() => setClickedFilter(false)}
                        sx={{ ml: 1, "&:hover": { cursor: "pointer" } }}
                        color="error"
                      />
                    </Box>
                  </FormControl>
                )}
                <TextField
                  label="Cauta client"
                  variant="outlined"
                  size="small"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  sx={{ ml: 4 }}
                />
              </Box>

              <TableContainer component={Paper} sx={{ mt: 10, width: "100%", margin: "0", maxWidth: "1600px" }}>
                <Table sx={{ width: "100%" }} size="small" aria-label="Orders table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Data</TableCell>
                      <TableCell align="center">Ora</TableCell>
                      <TableCell align="center">Nume</TableCell>
                      <TableCell align="center">Prenume</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Telefon</TableCell>
                      <TableCell align="center">Voucher</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          width: "15%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Status Email
                      </TableCell>
                      <TableCell align="center">Action</TableCell>
                      <TableCell align="center">Download Voucher</TableCell>
                    </TableRow>
                  </TableHead>
                  {!frontendOrders ? (
                    <TableBody>
                      <TableRow sx={{ backgroundColor: "lightgrey" }}>
                        <TableCell colSpan={10} sx={{ textAlign: "center", verticalAlign: "middle" }}>
                          <Typography sx={{ p: 2 }}>No data</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {frontendOrders.length > 0 ? (
                        frontendOrders
                          .filter((order) => {
                            const searchString = filterText.toLowerCase();
                            const customer = order.customerInfo;
                            return (
                              (customer.email && customer.email.toLowerCase().includes(searchString)) ||
                              (customer.firstName && customer.firstName.toLowerCase().includes(searchString)) ||
                              (customer.lastName && customer.lastName.toLowerCase().includes(searchString))
                            );
                          })
                          .map((order) => (
                            <TableRow
                              key={order.orderId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { background: "lightgray" },
                                height: "3rem",
                              }}
                              onClick={() => {
                                setSelectedFrontendOrder(order);
                                setOpen(true);
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {moment(order.createdAt).format("MM/DD/YYYY")}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {moment(order.createdAt).format("HH:MM")}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {order.customerInfo.firstName}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {order.customerInfo.lastName}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {order.customerInfo.email}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {order.customerInfo.phone}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {order.shoppingCartItems.map((shoppingCartItems) => shoppingCartItems.name)}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {"To be implemented"}
                              </TableCell>
                              <TableCell component="th" sx={{ width: "5%", textAlign: "center" }} scope="row">
                                {"To be implemented"}
                              </TableCell>
                              <TableCell component="th" sx={{ width: "10%" }} scope="row">
                                <Button
                                  startIcon={<SendIcon />}
                                  variant="contained"
                                  disabled={true}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // resendEmail(order);
                                  }}
                                >
                                  Resend
                                </Button>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Button variant="contained" disabled color="success">
                                  Download
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <Typography>No data to display</Typography>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </CustomTabPanel>
        </Box>
      )}
      <UserInformationModal open={open} handleClose={handleClose} selectedOrder={selectedOrder} />
    </Grid>
  );
};
