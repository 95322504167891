import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { getProducts, createProduct, deleteProduct, updateProduct } from "../../state/actions/getProducts.actions";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Snackbar,
  Chip,
  Grid,
} from "@mui/material";
import { IProducts } from "../../models/IProducts";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";

export const Products = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { products = [], loading, error } = useSelector((state: RootState) => state.products);
  const initialProductState = {
    _id: "",
    name: "",
    price: 0,
    description: [{ _id: "", text: "", icon: "" }],
    quantity: 0,
    season: "",
    duration: "",
    image: "",
    pageTags: [],
  };
  const [newProduct, setNewProduct] = useState<IProducts>(initialProductState);
  const [isEditing, setIsEditing] = useState(false);
  const [tagInput, setTagInput] = useState("");
  const [editingProductId, setEditingProductId] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewProduct({ ...newProduct, image: reader.result as string });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await dispatch(createProduct(newProduct));
      setSnackbarMessage("Product created successfully");
      setSnackbarSeverity("success");
      // Reset form after submission
      setNewProduct(initialProductState);
    } catch (err) {
      setSnackbarMessage("Failed to create product");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleSave = () => {
    if (isEditing && editingProductId) {
      dispatch(updateProduct({ id: editingProductId, product: newProduct }));
      setIsEditing(false);
      setEditingProductId(null);
      setSnackbarMessage("Product updated successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      // Reset form after saving
      setNewProduct(initialProductState);
    }
  };

  const handleEdit = (product: IProducts) => {
    setNewProduct(product);
    setIsEditing(true);
    setEditingProductId(product._id);
  };

  const handleDelete = async (productId: string) => {
    try {
      await dispatch(deleteProduct(productId)).unwrap();
      setSnackbarMessage("Product deleted successfully");
      setSnackbarSeverity("error");
    } catch (err) {
      setSnackbarMessage("Failed to delete product");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleDescriptionChange = (index: number, field: string, value: string) => {
    const newDescriptions = [...newProduct.description];
    newDescriptions[index][field] = value;
    setNewProduct({ ...newProduct, description: newDescriptions });
  };

  const handleAddDescription = () => {
    setNewProduct({
      ...newProduct,
      description: [...newProduct.description, { _id: "", text: "", icon: "" }],
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleAddTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && tagInput.trim() !== "") {
      setNewProduct({
        ...newProduct,
        pageTags: [...(newProduct.pageTags || []), tagInput.trim()], // Update the pageTag in newProduct state
      });
      setTagInput(""); // Clear the input field
      event.preventDefault(); // Prevent form submission
    }
  };

  const handleDeleteTag = (tagToDelete: string) => {
    setNewProduct({
      ...newProduct,
      pageTags: newProduct.pageTags?.filter((tag) => tag !== tagToDelete), // Remove the tag from the list
    });
  };
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingProductId(null);
    setNewProduct(initialProductState);
  };
  return (
    <Grid sx={{ padding: 3 }}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Products
        </Typography>
      </Grid>

      {loading === "pending" && (
        <Grid item display="flex" justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
      {loading === "failed" && <Alert severity="error">Error: {error}</Alert>}
      {loading === "succeeded" && (
        <Grid item xs={12}>
          <Paper sx={{ padding: 6 }}>
            <TableContainer>
              <Table aria-label="products table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Price</TableCell>
                    {/* <TableCell align="right">Description</TableCell> */}
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => (
                    <TableRow key={product._id}>
                      <TableCell component="th" scope="row">
                        {product.name}
                      </TableCell>
                      <TableCell align="right">{product.price}</TableCell>
                      {/* <TableCell align="right">
                    {product.description.map((desc, index) => (
                      <div key={index}>
                        <span className={`icon ${desc.icon}`} /> {desc.text}
                      </div>
                    ))}
                  </TableCell> */}
                      <TableCell align="right">
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() =>
                            isEditing && editingProductId === product._id ? handleSave() : handleEdit(product)
                          }
                        >
                          {isEditing && editingProductId === product._id ? (
                            <SaveIcon sx={{ color: "green" }} />
                          ) : (
                            <EditIcon sx={{ color: "danger" }} />
                          )}
                        </IconButton>
                        <IconButton onClick={() => handleDelete(product._id)}>
                          <DeleteIcon sx={{ color: "#f50057" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}
      <Paper sx={{ padding: 6, mt: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mt: 6 }} gutterBottom>
            Create Product
          </Typography>
        </Grid>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="name"
            value={newProduct.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          {newProduct.description.map((desc, index) => (
            <Box key={index} display="flex" alignItems="center" gap={2}>
              <TextField
                label={`Description ${index + 1}`}
                name="text"
                value={desc.text}
                onChange={(e) => handleDescriptionChange(index, "text", e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Enter icon"
                name="icon"
                value={desc.icon}
                onChange={(e) => handleDescriptionChange(index, "icon", e.target.value)}
                fullWidth
                margin="normal"
              />
            </Box>
          ))}
          <Button variant="contained" onClick={handleAddDescription} sx={{ marginTop: 2 }} startIcon={<AddIcon />}>
            Add Description
          </Button>
          <TextField
            label="Price"
            name="price"
            value={newProduct.price}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Quantity"
            name="quantity"
            value={newProduct.quantity}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Perioada zborului"
            name="season"
            value={newProduct.season}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Durata zborului"
            name="duration"
            value={newProduct.duration}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          {/* Page tags */}
          <TextField
            label="Add Page Tag"
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            onKeyDown={handleAddTag}
            variant="outlined"
            fullWidth
            margin="normal"
            helperText="Set a tag for a section page in the Frontend"
            sx={{ mb: 2 }}
          />
          <div>
            {newProduct.pageTags?.map((tag, index) => (
              <Chip key={index} label={tag} onDelete={() => handleDeleteTag(tag)} sx={{ margin: "0 4px 4px 0" }} />
            ))}
          </div>
          <Button variant="contained" component="label" sx={{ margin: "20px 0" }}>
            Upload Image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          <Button variant="contained" color="primary" type="submit" sx={{ ml: 2 }}>
            Create Product
          </Button>
          <Button variant="contained" color="success" sx={{ ml: 2 }} disabled={!isEditing} onClick={handleSave}>
            Save
          </Button>
          <Button variant="contained" color="secondary" sx={{ ml: 2 }} disabled={!isEditing} onClick={handleCancelEdit}>
            Cancel
          </Button>
        </form>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </Grid>
  );
};
