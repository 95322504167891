import React, { useEffect, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { patchGoogleSettings } from "../../state/actions/patchGoogleSettings.action";
import {
  setErrorGlobal,
  setSuccessGlobal,
} from "../../state/notificationSlice";
import { IGoogleDriveSettings } from "../../models/IGoogleDriveSettingsModel";
import { AppDispatch, RootState } from "../../state/store";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { getGoogleSettings } from "../../state/actions/getGoogleSettings.actions";

export const GoogleDrive: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: RootState) => state.settings.loading);
  const settings = useSelector((state: RootState) => state.settings.settings); 

  useEffect(() => {
    dispatch(getGoogleSettings());
  }, [dispatch]);

  const validationSchema = yup.object({
    googleDriveScopes: yup.string(),
    googleDriveFolderId: yup.string(),
  });

  const handleSubmit = async (
    values: IGoogleDriveSettings,
    { setSubmitting }: FormikHelpers<IGoogleDriveSettings>
  ) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    try {
      await dispatch(patchGoogleSettings(formData));
      dispatch(setSuccessGlobal({ message: "Data saved successfully!" }));
    } catch (error) {
      dispatch(setErrorGlobal({ message: `Something went wrong! ${error}` }));
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues: IGoogleDriveSettings = {
    _id: settings?._id,
    googleDriveScopes: settings?.googleDriveScopesUrl,
    googleDriveFolderId: settings?.googleDriveFolderId,
    daysToKeepFiles: settings?.daysToKeep,
  };

  
  return (
    <>
      <h1>Google Drive Settings</h1>
      {loading === "pending" ? (
        <CircularProgress />
      ) : loading === "idle" || loading === "succeed" ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            setFieldValue,
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
          }) => (
            <Form>
              {/* ######################################################################## */}

              {/* Scopes '[https://www.googleapis.com/auth/drive]' */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& .MuiTextField-root": { m: 1, width: "40ch" },
                }}
              >
                <TextField
                  id="google-scopes"
                  label="Google Drive Scopes URL default:[https://www.googleapis.com/auth/drive]"
                  name="googleDriveScopesUrl"
                  value={values.googleDriveScopesUrl}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.googleDriveScopesUrl &&
                    Boolean(errors.googleDriveScopesUrl)
                  }
                  helperText={
                    touched.googleDriveScopesUrl && errors.googleDriveScopesUrl
                  }
                />

                {/* ######################################################################## */}

                {/* Google Drive FolderID */}
                <TextField
                  id="googleFolderId"
                  label="Google Drive Folder ID"
                  name="googleDriveFolderId"
                  value={values.googleDriveFolderId}
                  onChange={handleChange}
                  error={
                    touched.googleDriveFolderId &&
                    Boolean(errors.googleDriveFolderId)
                  }
                  helperText={
                    touched.googleDriveFolderId && errors.googleDriveFolderId
                  }
                />

                {/* ######################################################################## */}
                <FormControl sx={{ m: 1, minWidth: 40, mt: 2 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Set days to keep files on Google Drive
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={values.daysToKeep}
                    label="Days"
                    onChange={handleChange}
                  >
                    <MenuItem value={14}>14 Days</MenuItem>
                    <MenuItem value={30}>30 Days</MenuItem>
                    <MenuItem value={60}>60 Days</MenuItem>
                  </Select>
                </FormControl>

                {/* ######################################################################## */}
              </Box>
              <Box sx={{ mt: 10 }}>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!touched || isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      ) : (
        loading === "failed" &&
        dispatch(setErrorGlobal({ message: "Failed while getting the data" }))
      )}
    </>
  );
};
