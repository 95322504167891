import axios from "axios";

// Action types
export const LOGIN_SUCCESS = 'LoginSuccess';
export const LOGOUT = 'Logout';

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const logout = async () => (
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/logout`, {})
);
