import axios, { AxiosProgressEvent } from "axios";


const upload = (
  file: File,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
  signal: AbortSignal,
  selectedFolder: string
): Promise<any> => {
  let formData = new FormData();

  formData.append("file", file);
  formData.append("googleDriveFolderId", selectedFolder)

  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/upload/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
    signal: signal,
  });
};

// const getFiles = (): Promise<any> => {
//   return axios.get("/upload");
// };

const FileUploadService = {
  upload,
  // getFiles
};

export default FileUploadService;
