import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getGoogleSettings = createAsyncThunk(
  "get/googleSettings",
  async () => {
    const settings = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/settings/getGoogleSettings`
    );

    return {settings: settings.data};
  }
);
