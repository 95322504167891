import {
  Box,
  Container,
  Typography,
  ImageList,
  ImageListItem,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import axios from "axios";
import { useParams } from "react-router-dom";
import { googleFileType } from "../../models/IGoogleFile";

type MediaParams = {
  id: string;
};
export const Media: React.FC = () => {
  const [listOfImages, seListOfImages] = useState<googleFileType[]>([]);
  const [listOfVideo, seListOfVideo] = useState<googleFileType[]>([]);
  console.log(listOfVideo);

  const { id } = useParams<MediaParams>();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/images/read`, {
          googleDriveFolderId: id,
        });
        if (response.data) {
          console.log(response.data);

          const videoFiles = response.data.filter((file: googleFileType) =>
            file.name.split(".").includes("mp4")
          );
          const imageFiles = response.data.filter(
            (file: googleFileType): any => {
              const extensions = file.name.split(".").pop()?.toLowerCase();
              return ["jpeg", "png"].includes(extensions || "");
            }
          );
          seListOfImages(imageFiles);
          seListOfVideo(videoFiles);
        }
      } catch (error) {
        console.error("An error occurred while fetching data: ", error);
      }
    }

    fetchData();
  }, [id]);
  return (
    <Container>
      <Box sx={{ mt: 5, display: "flex", alignItems: "center" }}>
        <PlayCircleOutlineIcon />
        <Typography sx={{ textAlign: "center", ml: 1 }}>
          Client Download Images and Video Page
        </Typography>
      </Box>
      {/* create a image list from material ui with pictures from google drive */}
      <Box sx={{ mt: 5 }}>
        <ImageList cols={4}>
          {listOfImages.map((image, index) => (
            <ImageListItem key={index}>
              <img
                src={image.webContentLink}
                alt={`img${index}`}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

      {/* insert the video preview of the file from google drive */}
      <Box sx={{ mt: 5 }}>
        {listOfVideo.map((videoItem, index) => (
          <>
            <video key={index} width="320" height="240" controls>
              <source
                src={videoItem.webContentLink}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <Box sx={{ mt: 5 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = videoItem.webContentLink;
                  link.download = videoItem.name;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link)
                }}
              >
                Download
              </Button>
            </Box>
          </>
        ))}
      </Box>
    </Container>
  );
};
