import React, { useState } from "react";
import { Container, Button, Typography, Box, Divider, Modal, TextField, Alert } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../state/actions/user.actions";
import axios from "axios";

const isDevelopment = process.env.NODE_ENV === "development";
export const LoginPage = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOauthLogin = () => {
    window.location.href = `/auth/google`;
    dispatch(loginSuccess());
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        isDevelopment
          ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/auth/login`
          : `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        { email, password },
        { withCredentials: true }
      );
      setUser(response.data.user);
      setError(null);

      localStorage.setItem("token", response.data.token);
      // navigate('/home/orders');
      setOpen(false);
      window.location.href = "/home/orders";
    } catch (error: any) {
      setError(error.response ? error.response.data.message : "Login failed");
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        mt: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <>
        <Box sx={{ width: "50%" }}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Unauthorized access restricted.
          </Typography>
        </Box>
        <Divider sx={{ width: "100%", mb: 2 }} />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="/images/Logo_color.png"
            alt="Logo"
            style={{
              marginLeft: 0,
              width: "250px",
              height: "auto",
              float: "left",
            }}
          />

          <Typography variant="h5" sx={{ textAlign: "center" }}>
            Login
          </Typography>
          <Button
            variant="contained"
            startIcon={<GoogleIcon />}
            onClick={handleOauthLogin}
            style={{ backgroundColor: "#4285F4", color: "#fff" }}
            sx={{ mt: 3 }}
          >
            Login with Google
          </Button>
        </Box>
        <Button onClick={handleOpen}>Login with user and password</Button>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "45%",
              height: "50%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Login with user and password
            </Typography>
            <Container maxWidth="sm">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ marginTop: "5rem" }}
              >
                <Typography variant="h4" component="h1" gutterBottom>
                  Login
                </Typography>
                <form onSubmit={handleLogin} style={{ width: "100%" }}>
                  <TextField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                  />
                  <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                  />
                  <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    Login
                  </Button>
                </form>
                {error && (
                  <Alert severity="error" style={{ marginTop: "16px" }}>
                    {error}
                  </Alert>
                )}
              </Box>
            </Container>
          </Box>
        </Modal>
      </>
    </Container>
  );
};
