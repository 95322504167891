import {
  Box,
  Container,
  Grid,
  Typography,
  CircularProgress,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Popover,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailIcon from "@mui/icons-material/Mail";
import SaveIcon from "@mui/icons-material/Save";
import html2canvas from "html2canvas";
import { useDispatch } from "react-redux";
import {
  clearNotification,
  setErrorGlobal,
  setSuccessGlobal,
} from "../../state/notificationSlice";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import QRCode from "qrcode.react";
import InfoIcon from "@mui/icons-material/Info";
import LoadingButton from "@mui/lab/LoadingButton";

interface VoucherImageProps {
  clientId: string;
}

export const VoucherImage: React.FC<VoucherImageProps> = ({ clientId }) => {
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const [voucherData, setVoucherData] = useState<any | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  //Snackbar state
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const sendVoucherToEmail = () => {
    if (imageRef.current) {
      html2canvas(imageRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("voucher/png");
        sendImageToServer(imgData);
      });
    }
  };
  const sendImageToServer = async (base64Image: any) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/email/sendEmail`, {
        image: base64Image,
        client: {
          email: voucherData.orderEmail,
          clientId: voucherData.clientId,
          first_name: voucherData.first_name,
          last_name: voucherData.last_name,
          clientName: `${voucherData.first_name} ${voucherData.last_name}`,
          phone: voucherData.phone,
          payment_status: "N/A",
        },
      });
      saveImageToDB(base64Image);
      if (response.status === 200) {
        setMessage("Email sent successfully");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setMessage("Error sending email");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const saveImageToDB = async (base64Image: any) => {
    try {
      const imageResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/voucher/saveVoucher`, {
        clientId,
        image: base64Image,
      });
      if (imageResponse.status === 200) {
        setMessage("Image saved successfully");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setMessage("Error saving image");
      setOpenSnackbar(true);
    }
  };
  const saveImageToLocal = () => {
    if (!imageRef.current) {
      return;
    }

    // Use html2canvas to take a screenshot of the ref element
    html2canvas(imageRef.current, { scale: 2 }).then((canvas) => {
      // Create a data URL from the canvas
      const image = canvas.toDataURL("image/png");

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = image;
      link.download = "VoucherImage.png"; // Define the image name here

      // Append the link to the body, click it, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const saveImage = () => {
    if (!imageRef.current) {
      return;
    } else {
      html2canvas(imageRef.current, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png").split(",")[1];
        const binaryString = window.atob(imgData);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a blob object from binary data
        const blob = new Blob([bytes.buffer], { type: "image/png" });

        axios
          .get("/voucher/getFolder", {
            params: { clientId: clientId },
          })
          .then((response) => {
            const { googleDriveFolderId } = response.data;

            const formData = new FormData();
            formData.append("image", blob, "voucher.png");

            return axios
              .post(
                `/voucher/saveImage?googleDriveFolderId=${googleDriveFolderId}&clientId=${clientId}`,
                formData
              )
              .then((response) => {
                dispatch(
                  setSuccessGlobal({ message: "Image saved successfully" })
                );
              })
              .catch((error) => {
                dispatch(
                  setErrorGlobal({
                    message: `Error while saving the image to Google Drive!:${error}`,
                  })
                );
              });
          })
          .catch((error) => {
            console.error("An error occurred:", error);
          });
      });
    }
  };

  useEffect(() => {
    const fetchOrderById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/getOrderById`, {
          params: { clientId: clientId },
        });
        setVoucherData(response.data);
        dispatch(setSuccessGlobal({ message: "Order received!" }));
      } catch (error) {
        dispatch(
          setErrorGlobal({ message: `Error while fetching orders. ${error}` })
        );
      }
    };
    fetchOrderById();
  }, [clientId, dispatch]);

  return (
    <Container sx={{ p: 3 }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={message}
      />
      {!voucherData ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress sx={{ mt: 10 }} />
        </Box>
      ) : (
        <>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "center", mt: 2, p: 2 }}
          >
            <Box
              ref={imageRef}
              sx={{
                width: "45rem",
                height: "20rem",
                backgroundColor: "#3f50b5",
                position: "relative",
                backgroundImage: `url('/images/bg-new.jpeg')`,
                backgroundPosition: "25% center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
                zIndex: 1,
                padding: 2,
              }}
            >
              {/* Overlay Box */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  zIndex: 2,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 3,
                  backgroundPosition: "left center",
                }}
              >
                <img
                  src="/images/Logo_color.png"
                  alt="Logo"
                  style={{
                    marginLeft: 0,
                    width: "190px",
                    height: "auto",
                    float: "left",
                  }}
                />
                <Box
                  sx={{
                    backgroundColor: "#3f50b5",
                    borderBottom: "1px white",
                    zIndex: 105,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      zIndex: 106,
                      color: "#FAFAFA",
                      fontWeight: "500",
                    }}
                    gutterBottom
                  >
                    {voucherData.voucher}
                  </Typography>
                </Box>
                {/* Order ID */}
                <Typography
                  variant="h6"
                  sx={{
                    zIndex: 106,
                    color: "#FAFAFA",
                    fontWeight: "500",
                    textAlign: "right",
                    marginRight: 2,
                    fontSize: 15,
                  }}
                  gutterBottom
                >
                  SN: #{voucherData.clientId.substring(0, 8)}
                </Typography>
                <Typography
                  sx={{
                    zIndex: 3,
                    fontSize: 15,
                    color: "#FAFAFA",
                    textAlign: "right",
                    marginRight: 2,
                  }}
                >
                  Valabil din data de: {new Date().toLocaleDateString("ro-RO")}
                </Typography>
                <Box sx={{ textAlign: "right", marginRight: 2 }}>
                  <QRCode
                    value="https://www.kronpara.ro"
                    size={80}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="Q"
                    includeMargin={true}
                  />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", mt: 20, ml: 3 }}
              >
                <>
                  <Typography
                    align="left"
                    sx={{
                      zIndex: 3,
                      fontSize: 18,
                      color: "#FAFAFA",
                      marginLeft: 30,
                    }}
                  >
                    Vă rugăm să ne contactați cu 48 de ore înainte de data
                    dorită pentru zbor, pentru a putea verifica condițiile
                    meteorologice și disponibilitatea locurilor.
                  </Typography>
                  <Typography
                    align="center"
                    sx={{
                      zIndex: 3,
                      fontSize: 20,
                      color: "#FAFAFA",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      marginTop: 2,
                      fontWeight: 600,
                    }}
                  >
                    <LocalPhoneIcon />
                    Telefon: 0735 628 164
                  </Typography>
                </>
              </Box>
              <Grid
                container
                sx={{
                  display: "flex",
                  height: "30px",
                  marginTop: 1,
                  borderTop: "1px solid white",
                  backgroundColor: "#3f50b5",
                  zIndex: 100000,
                  padding: 0,
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              >
                <Typography
                  sx={{
                    color: "#FAFAFA",
                    fontSize: 13,
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    textAlign: "left",
                    height: "30px",
                    display: "flex",
                    marginLeft: 1,
                    zIndex: 300,
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: 'Chocolate Classical Sans", sans-serif',
                    fontWeight: 400,
                    fontStyle: "normal",
                  }}
                  display="block"
                >
                  Valabilitatea voucherului este 1 an de zile de la data
                  achizitionarii | wwww.kronpara.ro | kronpara@gmail.com
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Button
            aria-describedby={id}
            variant="contained"
            endIcon={<InfoIcon />}
            onClick={handleClick}
            sx={{ mt: 2 }}
          >
            Detalii client
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ padding: 2 }}
          >
            <Typography
              align="left"
              sx={{
                fontSize: 12,
              }}
            >
              Nume: {`${voucherData.first_name} ${voucherData.last_name}`}
            </Typography>
            <Typography
              align="left"
              sx={{
                fontSize: 12,
              }}
            >
              Telefon: {voucherData.phone}
            </Typography>
            <Typography
              align="left"
              sx={{
                fontSize: 12,
              }}
            >
              Email: {voucherData.orderEmail}
            </Typography>
          </Popover>
          <Box sx={{ m: 4 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox onChange={(e) => setIsChecked(e.target.checked)} />
                }
                label="Agree with the Data"
              ></FormControlLabel>
            </FormGroup>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                color="success"
                onClick={saveImage}
                sx={{ whiteSpace: "nowrap" }}
                disabled={!isChecked}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={saveImageToLocal}
                sx={{ whiteSpace: "nowrap" }}
                disabled={!isChecked || !voucherData} // Ensure the button is disabled appropriately
                startIcon={<SaveIcon />}
              >
                Save to Local
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={sendVoucherToEmail}
                sx={{ whiteSpace: "nowrap" }}
                startIcon={<MailIcon />}
              >
                Send voucher to email
              </LoadingButton>
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};
