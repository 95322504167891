import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAppClientOrders } from "../models/IOrders";
import { fetchAppOrdersClients } from "./actions/fetchAppOrdersClient.actions";

const initialState = {
  appClients: [] as IAppClientOrders[],
  loading: "idle" as "idle" | "pending" | "succeeded" | "failed",
  error: null as string | null,
};

const appOrdersSlice = createSlice({
  name: "applicationOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppOrdersClients.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchAppOrdersClients.fulfilled,
        (state, action: PayloadAction<IAppClientOrders[]>) => {
          state.loading = "succeeded";
          state.appClients = action.payload;
          state.error = null;
        }
      )
      .addCase(fetchAppOrdersClients.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message || 'Failed to fetch clients';
      });
  },
});

export const { actions, reducer } = appOrdersSlice;
export default reducer;
