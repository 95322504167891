import axios from "axios";
import { IWordpressClient } from "../../models/IWordpressClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface FetchArgs {
  sortBy: string;
  order: string;
}
const isDevelopment = process.env.NODE_ENV === "development";

export const fetchWordpessClients = createAsyncThunk<IWordpressClient[], FetchArgs>(
  "clients/fetchWordpressClients",
  async ({ sortBy, order }, thunkApi) => {
    const clients = await axios.get<IWordpressClient[]>(
      isDevelopment
        ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/orders/getAllOrders`
        : `${process.env.REACT_APP_BACKEND_URL}/orders/getAllOrders`,
      {
        params: {
          order,
          sortBy,
        },
      }
    );

    return clients.data;
  }
);
