import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IWordpressClient } from "../models/IWordpressClient";
import { fetchWordpessClients } from "./actions/fetchWordpressClient.actions";


const initialState = {
  clients: [] as IWordpressClient[],
  loadingWebClients: 'idle' as 'idle' | 'pending' | 'succeeded' | 'failed',
  errorWebClients: null as string | null
};

const wordpressClientSlice = createSlice({
  name: "wordpressClient",
  initialState,
  reducers:{
   
  },
  extraReducers:(builder)=>{
    builder
    .addCase(fetchWordpessClients.pending, (state) =>{
        state.loadingWebClients = 'pending'
    })
    .addCase(fetchWordpessClients.fulfilled,(state, action: PayloadAction<IWordpressClient[]>) =>{
            state.loadingWebClients = 'succeeded'
            state.clients = action.payload
    })
    .addCase(fetchWordpessClients.rejected,(state)=>{
        state.loadingWebClients = 'failed'
        state.errorWebClients = 'Failed to fetch clients'
    })
  }
});

export const { actions, reducer } = wordpressClientSlice;
export default reducer;






