import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IGoogleDriveSettings } from "../models/IGoogleDriveSettingsModel";
import { patchGoogleSettings } from "./actions/patchGoogleSettings.action";
import { getGoogleSettings } from "./actions/getGoogleSettings.actions";

interface SettingsState {
  settings: null | IGoogleDriveSettings;
  loading: "idle" | "pending" | "succeed" | "failed";
}

const initialState: SettingsState = {
  settings: null,
  loading: "idle",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(patchGoogleSettings.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        getGoogleSettings.fulfilled,
        (state, action: PayloadAction<{ settings: IGoogleDriveSettings }>) => {
          state.loading = "succeed";
          state.settings = action.payload.settings;
        }
      )
      .addCase(
        patchGoogleSettings.fulfilled,
        (state, action: PayloadAction<{ settings: IGoogleDriveSettings }>) => {
          state.loading = "succeed";
          state.settings = action.payload.settings;
        }
      )
      .addCase(patchGoogleSettings.rejected, (state) => {
        state.loading = "failed";
      })
  },
});

export const { actions, reducer } = settingsSlice;
export default reducer;
