import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface NotificationState {
  msg: null | string;
  error: boolean | null;
  success: boolean | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  newOrder: number;
  unreadMessages: number;
}

const initialState: NotificationState = {
  msg: null,
  error: null,
  success: null,
  loading: "idle" as "idle" | "pending" | "succeeded" | "failed",
  newOrder: 0,
  unreadMessages: 0,
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setErrorGlobal: (state, action: PayloadAction<{ message: string }>) => {
      state.msg = action.payload.message;
      state.error = true;
      state.success = false;
    },
    setSuccessGlobal: (state, action: PayloadAction<{ message: string }>) => {
      state.msg = action.payload.message;
      state.success = true;
      state.error = false;
    },
    clearNotification: (state) => {
      state.msg = null;
      state.error = false;
      state.success = false;
    },
    incrementNewOrders: (state) => {
      state.newOrder += 1;
    },
    resetOrder: (state) => {
      state.newOrder = 0;
    },
    setUnreadMessages(state, action) {
      state.unreadMessages = action.payload;
    },
    incrementUnreadMessages(state) {
      state.unreadMessages += 1;
    },
    markMessagesAsRead(state) {
      state.unreadMessages = 0;
    },
  },
});

export const {
  setErrorGlobal,
  setSuccessGlobal,
  clearNotification,
  incrementNewOrders,
  resetOrder,
  setUnreadMessages,
  incrementUnreadMessages,
  markMessagesAsRead,
} = notificationSlice.actions;
export const { actions, reducer } = notificationSlice;
export default reducer;
