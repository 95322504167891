import { Container } from '@mui/material'

export const SendVoucher = () => {

  return (
    <Container>
      
    </Container>
  )
}
