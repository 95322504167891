import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { FrontendOrder } from "../../models/IFrontendOrders";

interface FetchArgs {
  sortBy: string;
  order: string;
}
const isDevelopment = process.env.NODE_ENV === "development";
export const fetchFrontendOrders = createAsyncThunk<FrontendOrder[], FetchArgs>(
  "clients/fetchFrontendOrders",
  async ({ sortBy, order }, thunkApi) => {
    const clients = await axios.get<FrontendOrder[]>(
      isDevelopment
        ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/app-orders/`
        : `${process.env.REACT_APP_BACKEND_URL}/app-orders/`,
      {
        params: {
          order,
          sortBy,
        },
      }
    );

    return clients.data;
  }
);
