import React from "react";
import { Box, BottomNavigation, BottomNavigationAction } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";

export const Footer = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  // STYLES
  const footerStyle: React.CSSProperties = {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };
  return (
    <Box style={footerStyle}>
      {/* <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Settings" onClick={() => navigate("settings")} icon={<SettingsIcon />} />
        <BottomNavigationAction label="Media" onClick={() => navigate("/media")} icon={<SubscriptionsIcon />} />
        <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
      </BottomNavigation> */}
    </Box>
  );
};
