import { configureStore } from "@reduxjs/toolkit";
import wordpressClientSlice from "./clientSlice";

import notificationSlice from "./notificationSlice";
import settingsSlice from "./settingsSlicer";
import { authReducer } from "./userSlice";
import appOrdersSlice from "./appOrdersSlice";
import productsSlice from "./productsSlice";
import frontendOrdersSlice from "./frontendOrdersSlice";

export const store = configureStore({
  reducer: {
    wordpressClient: wordpressClientSlice,
    appOrders: appOrdersSlice,
    frontendOrders: frontendOrdersSlice,
    login: authReducer,
    notifications: notificationSlice,
    settings: settingsSlice,
    products: productsSlice,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
