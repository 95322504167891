import { Box, Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import React from "react";

export const WordpressSettings = () => {
  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField id="outlined-basic" label="Consumer Key" />
        <TextField id="outlined-basic" label="Consumer Secret" />
      </Box>
      <Button
        variant="contained"
        startIcon={<SaveIcon />}
        sx={{ mt: 3 }}
        color="success"
      >
        Save
      </Button>
    </>
  );
};
