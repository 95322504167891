import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { LoginPage } from "./components/auth/LoginPage";
import { HomePage } from "./components/home";
import { Orders } from "./components/orders/Orders.component";
import { Upload } from "./components/upload";
import { Voucher } from "./components/voucher";
import { Settings } from "./components/settings";
import { Media } from "./components/clientMedia/Media";
import MainLayout from "./hoc/MainLayout";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./state/store";
import { setSuccessGlobal } from "./state/notificationSlice";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box, CircularProgress } from "@mui/material";
import { Invoices } from "./components/invoices/invoices";
import { Products } from "./components/products/Products.component";

const isDevelopment = process.env.NODE_ENV === "development";

export const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const logoutUser = () => {
    return async (dispatch: AppDispatch) => {
      try {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/logout`, { withCredentials: true });
        setUser(null);
        localStorage.removeItem("token");
        dispatch(setSuccessGlobal({ message: "You have successfully logged out!" }));
        window.location.href = "/";
      } catch (error) {
        console.error("Logout failed", error);
      }
    };
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get(
          isDevelopment
            ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/auth/login/success`
            : `${process.env.REACT_APP_BACKEND_URL}/auth/login/success`,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) return response.data;
          throw new Error("authentication has been failed!");
        })
        .then((resObject) => {
          setUser(resObject.user);
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem("token");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const signOut = () => {
    dispatch(logoutUser());
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <MainLayout>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="home" element={user ? <HomePage signOut={signOut} /> : <LoginPage />}>
              <Route path="orders" element={user ? <Orders /> : <LoginPage />} />
              <Route path="products" element={user ? <Products /> : <LoginPage />} />
              <Route path="upload" element={user ? <Upload /> : <LoginPage />} />
              <Route path="voucher" element={user ? <Voucher /> : <LoginPage />} />
              <Route path="settings" element={user ? <Settings /> : <LoginPage />} />
              <Route path="media/:id" element={user ? <Media /> : <LoginPage />} />
              <Route path="invoices/:id" element={user ? <Media /> : <LoginPage />} />
              <Route path="invoices" element={user ? <Invoices /> : <LoginPage />} />
              <Route path="media/:id" element={user ? <Media /> : <LoginPage />} />
            </Route>
          </Routes>
        </MainLayout>
      </BrowserRouter>
    </div>
  );
};
