import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Paper,
  Alert,
  LinearProgress,
  Divider,
  TextField,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IFile from "../../models/IFile";
import UploadService from "../services/UploadService";
import { useDropzone } from "react-dropzone";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import { socket } from "../../socketio/socket";
import axios, { AxiosResponse } from "axios";
import  IFolder  from "../../models/IFolder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const Upload = () => {
  const [videoFiles, setVideoFiles] = useState<File>();
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [fileInfos, setFileInfos] = useState<IFile[]>([]);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [selectFolderError, setSelectFolderError] = useState<boolean>(false);
  const [disableCancel, setDisableCancel] = useState<boolean>(true);
  const [newFolder, setNewFolder] = useState<boolean>(false);
  const [googleFolders, setGoogleFolders] = useState<IFolder[]>([]);
  const [folderName, setFolderName] = useState<string>("");
  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    if (uploadProgress === 0) {
      setDisableCancel(true);
    }
  }, [uploadProgress]);

  useEffect(() => {
    const fetchGoogleFolders = async () => {
      try {
        const response: AxiosResponse<IFolder[], any> = await axios.get<
          IFolder[]
        >(`${process.env.REACT_APP_BACKEND_URL}/upload/folders`);
        setGoogleFolders(response.data);
      } catch (error) {
        console.log("Could not fet google drive folders");
      }
    };
    fetchGoogleFolders();
  }, []);

  const upload = () => {
    setUploadProgress(1);
    setDisabledButton(true);
    setDisableCancel(false);
    if (!videoFiles) {
      return;
    }

    if (selectedFolder !== null) {
      UploadService.upload(
        videoFiles,
        (event: any) => {},
        signal,
        selectedFolder
      )
        .then((response: any) => {
          setSuccessMessage(response.data.message);
        })
        .catch((error: any) => {
          if (axios.isCancel(error)) {
            setErrorMessage("Upload cancelled");
          } else {
            setErrorMessage(`Could not upload the File. ${error}`);
          }
          setUploadProgress(0);

          setVideoFiles(undefined);
        });
    } else {
      setSelectFolderError(true);
    }
  };
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (errorMessage || successMessage) {
      setUploadProgress(0);
      setDisabledButton(false);
      timer = setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
        socket.disconnect();
      }, 10000);
    }
    // when component unmounts
    return () => {
      clearTimeout(timer);
    };
  }, [errorMessage, successMessage, uploadProgress]);

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    const selectedFile = selectedFiles[0];

    const fileInfo: IFile = {
      name: selectedFile.name,
      size: `${(selectedFile.size / (1024 * 1024)).toFixed(2)} MB`,
      progress: 0,
      uploaded: false,
    };

    setVideoFiles(selectedFiles?.[0]);
    setFileInfos([fileInfo]);
    setUploadProgress(0);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // const myFileArray: IFile[] = acceptedFiles.map((file) => ({
    //   name: file.name,
    //   size: file.size,
    //   progress: 0,
    //   uploaded: false,
    // }));
    const selectedFile = acceptedFiles[0];
    const fileInfo: IFile = {
      name: selectedFile.name,
      size: `${(selectedFile.size / (1024 * 1024)).toFixed(2)} MB`,
      progress: 0,
      uploaded: false,
    };
    setFileInfos([fileInfo]);
    setUploadProgress(0);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const cancelUpload = () => {
    controller.abort();
    setDisabledButton(false);
    setDisableCancel(true);
    setUploadProgress(0);
  };

  const handleCreateNewFolder = async () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/orders/create`, {
      clientId: uuidv4(),
      folderName: folderName,
    });
    setNewFolder(false);
  };
  const handleFolderSelection = (folderId: string) => {
    if (selectedFolder === folderId) {
      setSelectedFolder(null); // Deselect the folder if it's already selected
    } else {
      setSelectedFolder(folderId); // Otherwise, select the folder
    }
  };

  return (
    <Container>
      <Box sx={{ alignItems: "center", display: "flex" }}>
        <VideoCallIcon sx={{ mr: 1, color: "#1769aa", fontSize: 28 }} />
        <Typography>Upload Video</Typography>
      </Box>
      {uploadProgress !== 0 && (
        <Box sx={{ width: "100%", mt: 8 }}>
          <Typography>Upload in progress..</Typography>
          <LinearProgress sx={{ mt: 2 }} />
        </Box>
      )}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {selectFolderError && (
        <Alert severity="error">Please select a folder!</Alert>
      )}
      <Box
        sx={{
          backgroundColor: "#cfd8dc",
          height: 150,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 5,
          borderRadius: 5,
          "&:hover": { background: "lightgray", boxShadow: 1 },
        }}
        {...getRootProps()}
      >
        <CloudUploadIcon />
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography
            variant="caption"
            display="block"
            sx={{ color: "#fafafa" }}
          >
            Drop the files here ...
          </Typography>
        ) : (
          <Typography
            variant="overline"
            display="block"
            sx={{ color: "#818181", m: 2 }}
          >
            Drag 'n' drop some files here, or click to select files
          </Typography>
        )}
      </Box>
      <TableContainer component={Paper} sx={{ mt: 10 }}>
        <Table aria-label="uploaded-files">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 800 }}>File name</TableCell>
              <TableCell sx={{ width: 200 }}>Size</TableCell>
              <TableCell>Uploaded</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fileInfos.length > 0 ? (
              fileInfos?.map((file, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {file.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {file.size}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {file.uploaded}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography>No files</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Google drive Folder Table */}
      <Box sx={{ mt: 7, alignItems: "center", display: "flex" }}>
        <AddToDriveIcon sx={{ fontSize: 42, color: "#4285F4" }} />
        <Typography sx={{ ml: 1 }}>
          Select the folder from Google drive to Upload the file
        </Typography>
      </Box>
      <Divider sx={{ borderColor: "#eeff41" }} />
      <Table sx={{ mt: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell width={2}>Select</TableCell>
            <TableCell sx={{ ml: 20 }}>Folder Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {googleFolders.map((folder) => (
            <TableRow
              sx={{ "&:hover": { background: "lightgray" } }}
              onClick={() => handleFolderSelection(folder.googleDriveFolderId)}
            >
              <TableCell component="th" scope="row">
                <Checkbox
                  checked={selectedFolder === folder.googleDriveFolderId}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleFolderSelection(folder.googleDriveFolderId);
                  }}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {folder.folderName}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        component="label"
        variant="contained"
        color="success"
        sx={{ mt: 4 }}
        onClick={() => {
          setNewFolder(true);
        }}
        startIcon={<CreateNewFolderIcon />}
      >
        New folder
      </Button>
      {newFolder && (
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <TextField
            id="outlined-basic"
            label="Folder Name"
            variant="outlined"
            name="folderName"
            onChange={(e) => setFolderName(e.target.value)}
          />
          <CheckCircleIcon
            color="success"
            sx={{
              ml: 1,
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={handleCreateNewFolder}
          />
          <CloseIcon
            onClick={() => {
              setNewFolder(false);
            }}
            color="error"
            sx={{
              ml: 1,
              "&:hover": {
                cursor: "pointer",
              },
            }}
          />
        </Box>
      )}
      <Box sx={{ mt: 10 }}>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Select file
          <VisuallyHiddenInput type="file" onChange={selectFile} />
        </Button>
        <Tooltip
          title="You must select a folder or a file to upload"
          disableHoverListener={!disabledButton && !!selectedFolder}
        >
          <span>
            <Button
              sx={{ ml: 2 }}
              component="label"
              variant="contained"
              disabled={disabledButton || !selectedFolder}
              onClick={upload}
            >
              Upload
            </Button>
          </span>
        </Tooltip>
        <Button
          sx={{ ml: 2 }}
          variant="outlined"
          color="error"
          disabled={disableCancel}
          onClick={cancelUpload}
        >
          Cancel
        </Button>
      </Box>
    </Container>
  );
};
